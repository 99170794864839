@import "../../styles/variable.scss";

.header-action {
  display: flex !important;
  margin-left: 76% !important;
}

.welcome-text {
  font-size: 24px !important;
}

.landing-toolbar {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.menu-button {
  margin-left: 8px !important;
  margin-right: 20px !important;
  padding: 6px !important;
}
.text-size {
  font-size: 16px !important;
  font-family: $fontFamily;
}

/*background for menu*/
/* TODO needs to change later*/
.menu-background-img {
  background-image: url("../../assets/menu.png");
  background-repeat: no-repeat, repeat;
  padding-top: 76px !important;
}

.menu-background-white {
  background-image: url("../../assets/menu.png");
  background-repeat: no-repeat, repeat;
  padding-top: 76px !important;
}

.user-profile {
  text-transform: none !important;
}

.user-profile span {
  padding-left: 12px;
}

/*Styles for pods*/
.pod-name {
  padding-top: 10px;
  text-align: left;
  padding-left: 10px;
}
.pod-name span:first-child {
  font-size: 14px;
  font-weight: 500;
}

.pod-name span:first-child + span {
  font-size: 14px;
  color: $grayColor;
}

.pod-count {
  font-size: 28px;
  text-align: right;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 28px;
  bottom: 19px;
}

/*Style for action bar */
.action-bar {
  margin-top: 25px;
  display: flex;
  margin-bottom: 25px;
  float: right;
}

.action-bar-fix {
  margin-top: 32px;
  display: flex;
  margin-bottom: 36px;
  float: right;
  padding: 0 5px;
}

#search-button {
  display: flex;
}

.showSearchBar {
  margin-left: 61.18%;
}
.delete-icon {
  width: 40px !important;
  height: 40px !important;
  margin-right: 15px !important;
  margin-left: 15px !important;
  box-shadow: none !important;
  border: 1px solid $lightGray;
}

.viewActionButton {
  min-height: 38px;
}

.primary-button {
  background-color: $themeColor !important;
  color: #fdfdfd !important;
  font-size: 14px !important;
  padding: 7px 19px !important;
  border-radius: 24.5px !important;
  border: 0px solid $themeColor !important;
  text-transform: none !important;
  font-family: $fontFamily;

  &.Mui-disabled {
    opacity: 0.5 !important;
  }
}
.white-button {
  background-color: rgba(0, 0, 0, 0) !important;
  color: $themeColor !important;
  font-size: 14px !important;
  text-transform: none !important;
  font-family: $fontFamily;
  border-radius: 100px !important;
  margin-right: 15px !important;
  border: 1px solid $themeColor !important;
}
.heading-text-size {
  font-family: $fontFamily;
  font-size: 16px !important;
  font-weight: bold;
  color: $tableTdCellColorUnexpanded;
}
#search-input {
  height: 42px !important;
  margin-right: 2px;
  margin-top: 0;
  border: 1px solid #dfdfdf;
  border-radius: 4px;
  padding: 2px 10px;
  background: #fff;
  text-align: left;
  color: #181919;
  font-family: $fontFamily;
  font-size: 14px;
}

.proposalList {
  margin: 0;

  &.standalone {
    margin: 24px 34px 0px 96px;
  }
}
svg {
  font-size: 24px !important;
}
