.advertiser-change {
  width: 800px;
}

.change-adv-field-list {
  list-style-type: none;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #dddddd;
  border-radius: 4px;

  li {
    padding-left: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
  }
}

.change-advertiser-action.MuiDialogActions-root {
  padding-bottom: 20px;
  padding-top: 8px;
  padding-left: 8px;
  padding-right: 8px;
}

.bold-title {
  font-weight: 700;
}

.action-details {
  color: green;
}
