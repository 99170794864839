@import "../.././../styles/variable.scss";

.ag-theme-balham .ag-row-odd,
.ag-row-focus {
  background-color: #f5f5f5;
}
.ag-theme-balham .ag-header-row {
  background-color: rgb(248, 248, 248);
  font-size: 14px;
  color: #505050;
}

.ag-header {
  height: 100px !important;
}
.ag-pivot-off {
  min-height: 78px !important;
}
.ag-header-viewport {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.ag-side-bar {
  box-sizing: border-box;
  border: 1px solid $borderColor !important;
  border-radius: 0 0 4px 0;
  background-color: white !important;
  padding-right: 5px;
  padding-left: 5px;
  border-right-width: 1px !important;
}

.ag-header-row {
  height: 16px;
  width: 49px;
  color: #505050;
  font-family: $fontFamily_roboto;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
}

.ag-header-cell {
  border: 0 !important;
  border-right: 1px solid #dfdfdf !important;
  .ag-input-wrapper {
    height: 32px;
  }
  .ag-floating-filter-input {
    box-sizing: border-box;
    height: 32px;
    width: 157px;
    border: 1px solid #dfdfdf !important;
    border-radius: 4px;
    background-color: $backgroundOptionsBox;
    .ag-text-field-input {
      box-shadow: none !important;
      border: none !important;
    }

    .CustomDatePicker {
      height: 32px;

      .MuiOutlinedInput-root {
        height: 32px;
        padding: 0;

        input {
          padding: 0;
        }

        .MuiOutlinedInput-notchedOutline {
          border: 0 none !important;
        }
      }
    }
  }
  .ag-floating-filter-button {
    margin-left: 2px !important;
  }
  .ag-icon-filter {
    font-size: 22px;
  }
}

.ag-theme-balham .ag-ltr .ag-header-cell::after {
  content: none !important;
}

.ag-row,
.ag-cell {
  font-size: 14px;
  color: #505050;
}

.ag-row {
  border-color: #dfdfdf !important;
}

.ag-cell {
  border: none !important;
  line-height: 39px !important;
}

.ag-theme-balham {
  font-size: 14px;
  color: #505050;
  width: 100%;

  .ag-root {
    border-color: $optionBoxBorder;
    border-radius: 4px;
  }

  .ag-header {
    background-color: #f5f5f5;
    border-bottom-color: $optionBoxBorder;
  }
}

.campaign-name {
  line-height: 20px;
}
.campaign-order-display {
  cursor: pointer;
}
.campaign-order-display:hover {
  text-decoration: underline;
}

.ag-grid-cell {
  width: 75px;
  color: #505050;
  font-family: $fontFamily_roboto;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
}
