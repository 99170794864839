$whiteColor: #fff;
$redColor: #ff2932;
$orangeColor: #f0825f;
$greenColor: #51b384;
$lightGray: lightgray;
$blackColor: #000;
$blueColor: #29acdc;
$grayColor: gray;
$backgroundGray: #ececec;
$textColor: #191919;
$whiteTextColor: #fdfdfd;
$lightGrayBackground: #e7e7e7;
$chipStyleColor: #f0f0f1;
$borderColor: #f0f0f1;
$scrollThumbColor: #cbcbcb;
$errorColor: #f44336;
$colorOptionTitle: #9ea0a5;
$labelColor: #a0a0a0;
$backgroundWhite: #f8f8ff;
$imgColor: #888a8f;
$formControlBorder: #a5a5a5;
$greyColor: #505050;
$alternateRecordColor: #f8f8f8;

//Theme color for marketron Radigios
$themeColor: #29acdc;
$themeLinkColor: #0b7eb5;

//font-family for entire Application
$fontFamily: "Lato", Helvetica, Arial, Verdana, Tahoma, sans-serif !important;
$fontFamily_regular: Lato Regular !important;
$fontFamily_bold: Lato Bold !important;
$fontFamily_lato: Lato !important;
$fontFamily_roboto: "Roboto", Helvetica, Arial, Verdana, Tahoma, sans-serif;

//font-weight

$fontWeight_bold: bold;

//Theme based font-sizes
$fontSize_22px: 22px;
$fontSize_16px: 16px;
$fontSize_14px: 14px;

//MUI TABLE COMMON STYLE
$defaultRowColor: #fff;
$alternateRowColor: #f8f8f9;
$tableTdPadding: 0px 6px 0px 25px !important;
$tableTdHeight: 56px !important;
$tableHeaderCellFontSize: 11px !important;
$tableHeaderCellColor: #1c1c1c;
$tableTdCellFontSize: 14px !important;
$tableTdCellColor: #585858 !important;
$tableTdCellColorUnexpanded: #585858;

//STATUS COLOR CODE
$statusNewColorCode: #f0825f;
$statusDraftColorCode: #f0825f;
$statusOrderGenerated: #51b384;
$statusCompleteColorCode: #43beeb;
$statusRunningColorCode: #43beeb;
$statusFinishedColorCode: #43beeb;
$statusPausedColorCode: #43beeb;

//ORDER STATUS COLOR CODE
$orderStatusNewColorCode: #51b384;
$orderStatusRevisionColorCode: #43beeb;
$orderStatusPendingColorCode: #f0825f;

//CAMPAIGN STATUS COLOR CODE
$orderstatusStagedColorCode: #f0825f;
$orderstatusFailedColorCode: #ff2932;
$orderStatusRunningColorCode: #43beeb;
$orderStatusPausedColorCode: #b0b0b0;
$orderStatusFinishedColorCode: #f0825f;

// EXPANSION STATUS
$expansionSummaryMarginBottom: 26px;

// FORM FIELDS
$fieldWidth368: 368px;
$fieldHeight56: 56px;
$fieldWidth136: 136px;

$sectionTitleFont: 16px;

//switch label text
$labelText: #757575;
$textFieldLabel: #a0a0a0;

//options box
$backgroundOptionsBox: #ffffff;
$optionBoxBorder: #f0f0f1;

//location
$locationElementLabelColor: #a4a4a4;
$locationDivision: #f0f0f1;
