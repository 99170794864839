@import "../../styles/variable.scss";

.bg-art-network {
  text-align: center;
  position: relative;
  margin-bottom: -7%;
}
.bg-layer {
  position: absolute;
  bottom: 0px;
  width: 100%;
}
.bg-art-network img {
  width: 70%;
}

.bg-oval-bottom img {
  width: 100%;
}
.loginForm {
  z-index: 999;
  display: "flex";
  justify-content: "center";
}

.login-buttom {
  width: 100%;
  height: 43px;
  color: $whiteColor !important;
  margin: 0% !important;
  font-size: 18px !important;
  text-transform: none !important;
  background: $themeColor !important;
  font-family: $fontFamily !important;
}

/* .MuiCardActionsroot-0-1-154{
    padding: 8px 0px !important;
} */

.error-style {
  color: $redColor;
  font-family: $fontFamily;
  font-size: 12px;
  margin: 20px 0 0px !important;
}

.MuiPaperelevation1-0-2-5 {
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 2px 1px -1px rgba(0, 0, 0, 0.12) !important;
}

.checkbox-style {
  color: #2ec4e8 !important;
}
.remeber-me label {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  vertical-align: none !important;
  -webkit-tap-highlight-color: none !important;
  font-size: 14px !important;
  font-family: $fontFamily !important;
}
.MuiFormControlLabelroot-0-2-107 {
  margin-right: 26% !important;
}
.MuiInputBaseinput-0-2-75 {
  display: inline-block !important;
}
.MuiOutlinedInputroot-0-1-52.MuiOutlinedInputfocused-0-1-53 .MuiOutlinedInputnotchedOutline-0-1-59 {
  border-color: #2ec4e8 !important;
}
.login-card {
  z-index: 999;
  padding: 2%;
  width: 31%;
}
.inputSize label,
.inputSize input {
  font-size: 14px !important;
  font-family: $fontFamily !important;
}

.login-form-container {
  display: flex;
  justify-content: center;
  padding-top: 5%;

  .error-div {
    margin-top: 17px;
  }

  .error-div-no-error {
    margin-top: 17px;
  }
}
