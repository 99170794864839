@import "../../../../src/styles/variable.scss";

.mui-table-common-class {
  .MuiPaper-root {
    width: 100% !important;
    & > table {
      margin-top: 30px;
      tfoot tr td {
        background-color: #f0f0f1;
      }
    }
  }
  table {
    tbody {
      cursor: pointer;
      tr {
        td {
          padding: $tableTdPadding;
          height: $tableTdHeight;
          cursor: pointer;
        }
        td:last-child {
          padding-right: 10px !important;
        }
      }
      tr:first-child {
        .MuiFormControl-root .MuiInputAdornment-root svg {
          font-size: 20px !important;
          color: #585858;
        }
      }
    }
    tfoot tr td .MuiTablePagination-toolbar {
      justify-content: center;
      .MuiTablePagination-spacer {
        display: none;
      }
    }
  }
  .MuiCircularProgress-colorPrimary {
    color: $themeColor;
    height: 50px;
    width: 50px;
    border-width: 3px;
  }
}

.customized-column-menu {
  .MuiPaper-root {
    top: 101px !important;
    left: 1089px !important;
  }
}
.column-config-action {
  width: 21px;
  height: 21px;
  text-align: right;
  float: right;
  position: absolute;
  z-index: 99;
  right: 30px;
  top: 18px;
  svg {
    width: 21px;
    height: 21px;
    background: $lightGrayBackground;
    border: 1px solid $lightGrayBackground;
    border-radius: 100px;
  }
}
.MuiTableCell-root {
  padding: $tableTdPadding;
  height: $tableTdHeight;
  font-family: $fontFamily;
}

tfoot > tr > td.MuiTableCell-root {
  padding: 0 !important;
  overflow-x: visible !important;
}

.MuiTableHead-root {
  tr {
    th {
      font-weight: bold !important;
      color: $tableHeaderCellColor;
      font-size: $tableHeaderCellFontSize;
      text-transform: uppercase;
      max-width: 100px;
    }
    td {
      font-size: $tableTdCellFontSize;
      color: $tableTdCellColor;
      border-bottom: none !important;
    }
  }
}
.MuiTableRow-head {
  border-bottom: 1px solid $lightGrayBackground !important;
}
.MuiTableBody-root {
  td {
    border-bottom: none !important;
  }
}
.custom-cell-status {
  ul {
    border: 0.5px solid #585858;
    border-radius: 15px;
    width: max-content;
    padding-right: 14px;
    padding-left: 24px;
    list-style: none;
    margin-top: 8px;
    margin-bottom: 5px;
    height: 24px;
    line-height: 24px;

    li::before {
      content: "\2022";
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }
    .New::before {
      color: $statusNewColorCode;
    }
    .Draft::before {
      color: $statusDraftColorCode;
    }
    .Order-Generated::before {
      color: $statusOrderGenerated;
    }
    .Complete::before {
      color: $statusCompleteColorCode;
    }
    .Running::before {
      color: $statusRunningColorCode;
    }
    .Finished::before {
      color: $statusFinishedColorCode;
    }
    .Paused::before {
      color: $statusPausedColorCode;
    }
    .Order_new::before,
    .Order_New::before {
      color: $orderStatusNewColorCode;
    }
    .Order_Revision::before,
    .Order_revision::before {
      color: $orderStatusRevisionColorCode;
    }
    .Order_Pending::before,
    .Order_pending::before {
      color: $orderStatusPendingColorCode;
    }

    .Campaign_Staged::before,
    .Campaign_staged::before {
      color: $orderstatusStagedColorCode;
    }
    .Campaign_Failed::before,
    .Campaign_failed::before {
      color: $orderstatusFailedColorCode;
    }
    .Campaign_Running::before,
    .Campaign_running::before {
      color: $orderStatusRunningColorCode;
    }
    .Campaign_Paused::before,
    .Campaign_paused::before {
      color: $orderStatusPausedColorCode;
    }
    .Campaign_Finished::before,
    .Campaign_finished::before {
      color: $orderStatusFinishedColorCode;
    }
    .Staged {
      color: $orderStatusFinishedColorCode;
    }
  }
}

.custom-cell-creative-status {
  ul {
    padding: 0;
    margin: 0;
    line-height: 17px;
    white-space: normal;

    li {
      list-style: none;
      width: 48%;
      display: inline-block;
      margin-right: 2px;

      span {
        text-transform: capitalize;
      }
    }
  }
}

.ProposalListClass,
.order-list,
.table-container {
  margin-bottom: 25px !important;
}

.ProposalListClass {
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  }
  .MuiInput-underline:after {
    border-bottom: 1px solid rgba(0, 0, 0, 0);
  }
}

.ProposalListClass .MuiPaper-elevation2,
.order-list .MuiPaper-elevation2,
.table-container .MuiPaper-elevation2 {
  box-shadow: none;
  border: 1px solid #f0f0f1;
}

.ProposalListClass table tbody tr td:not(:first-child).MuiTableCell-body,
.order-list table tbody tr td:not(:first-child).MuiTableCell-body,
.table-container table tbody tr td:not(:first-child).MuiTableCell-body {
  color: #585858;
}

#customized-menu ul.MuiMenu-list li {
  padding: 5px 40px 5px 20px;
  min-height: 35px;
  .MuiCheckbox-root {
    padding: 0;
    margin: 0 9px;
    font-size: 12px;
    svg {
      height: 17px;
      width: 17px;
    }
  }
  .MuiFormControlLabel-label {
    font-size: 12px !important;
    color: #585858;
  }
  &:not(.list-item-checked) .MuiFormControlLabel-label {
    color: #b6b6b6;
  }
}

#customized-menu ul.MuiMenu-list {
  padding: 0;
  :hover,
  :active,
  :focus {
    background-color: #fff;
  }
}

#customized-menu ul.MuiMenu-list li:first-child {
  padding-left: 0;
  padding-right: 0;
  :hover,
  :active,
  :focus {
    background-color: #fff;
  }
  .MuiListItemIcon-root {
    display: block;
    width: 100%;
    font-family: $fontFamily;
    .column-item-label {
      font-size: 11px;
      display: block;
      color: #191919;
      font-weight: bold;
      padding: 5px 20px;
      min-height: 35px;
    }
    .column-reset {
      display: block;
      color: #8e94a0;
      width: 100%;
      text-align: right;
      padding-right: 15px;
      border-bottom: 1px solid #f4f4f4;
      line-height: 0.1em;
      margin: 5px 0 8px 0;
      font-size: 10px;
      span {
        background: #fff;
        padding: 0 10px;
        border: 1px solid #f4f4f4;
        border-radius: 8.5px;
      }
    }
  }
  .MuiTouchRipple-root {
    display: none;
  }
}
