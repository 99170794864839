@import "../../styles/variable.scss";

.list-style {
  width: 400px;
  overflow-y: scroll;
  list-style-type: none;
  margin-left: 21%;
  height: 200px;
  border: 1px solid $lightGray;
}
.list-style-poi {
  width: 400px;
  overflow-y: scroll;
  list-style-type: none;
  margin-left: 39%;
  height: 200px;
  border: 1px solid $lightGray;
}
.list-item {
  border-bottom: 1px solid $lightGray;
  text-align: left;
  padding-left: 2px;
}
.list-item:hover {
  background: $lightGray !important;
}

.search-box {
  width: 400px !important;
  margin-right: 10px;
}

.mapcontainer {
  margin: 10px;
  border: 1px solid $lightGray;
}
.load-regions-button {
  margin-left: 10px;
}

.zip-box {
  resize: none;
  overflow-y: scroll;
  margin-left: 5px;
}

.map-failure-container {
  height: 295px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.location-error {
  display: flex;
  width: 100%;
  text-align: center;
  justify-content: center;
  align-content: center;
}

.map-error-message {
  text-align: center;
  align-items: center;
}
