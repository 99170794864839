@import "./../../../styles/variable.scss";

.MuiAutocomplete-noOptions {
  font-family: $fontFamily;
  font-size: 14px;
}

.MuiAutocomplete-popupIndicator .MuiIconButton-label {
  background-repeat: no-repeat;
  background-position: center;
  height: 24px;
  width: 27px;
}

.MuiAutocomplete-popupIndicator .MuiIconButton-label svg {
  display: none;
}

.advertiser-combo-box {
  display: inline-flex;
  position: relative;

  .text-popup-button {
    cursor: pointer;
    position: absolute;
    right: 12px;
    top: 16px;

    span {
      color: $themeLinkColor;
      font-family: $fontFamily;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 15px;
    }
  }
}
