@import "./variable.scss";
@import "../components/login-page/login.scss";
@import "../components/mapBox/mapBox.scss";
@import "../components/commons/autoComplete/autoCompleteComboBox.scss";
@import "../components/commons/mui-table/muiTableStyle.scss";
@import "../components/commons/ag-grid/agGrid.scss";
@import "../components/commons/dialog-advertiser-change/dialogAdvertiser";

body {
  background: #ffffff !important;
  margin: 0;
  padding: 0;
  font-family: $fontFamily;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $textColor;
}

code {
  font-family: $fontFamily;
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: $themeColor !important;
}

.MuiIconButton-colorPrimary,
.MuiIcon-colorPrimary,
.MuiTab-textColorPrimary.Mui-selected {
  color: $themeColor !important;
}

.MuiTabs-indicator {
  background-color: $themeColor !important;
}

b,
th {
  font-weight: 500 !important;
}

h3 {
  font-weight: 500 !important;
}

.MuiIconButton-root:hover {
  background-color: rgba(0, 0, 0, 0) !important;
}

.MuiIconButton-root:active {
  background-color: rgba(0, 0, 0, 0) !important;
}

.MuiButton-contained {
  box-shadow: none !important;
}

.MuiOutlinedInput-notchedOutline {
  // border-color: #b0b0b0 !important; //TO DO
  border-width: 1px !important;
}

.MuiAutocomplete-root.Mui-focused {
  .MuiOutlinedInput-notchedOutline {
    border-color: #0b7eb5 !important;
  }
  .MuiAutocomplete-inputRoot {
    border-color: #0b7eb5 !important;
  }
}
.MuiAutocomplete-root.Mui-focused:hover {
  border-color: #0b7eb5 !important;
  .MuiOutlinedInput-notchedOutline {
    border-color: #0b7eb5 !important;
  }
  .MuiAutocomplete-inputRoot {
    border-color: #0b7eb5 !important;
  }
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #a5a5a5 !important;
}

.MuiOutlinedInput-root.Mui-focused {
  border-color: #0b7eb5 !important;
  .MuiOutlinedInput-notchedOutline {
    border-color: #0b7eb5 !important;
  }
}

.Mui-disabled:hover {
  cursor: not-allowed !important;
}
.close_action {
  cursor: pointer;
}
.redirect-to-list {
  margin-top: 28px;
  padding-left: 0;
  padding-right: 0;
  font-size: 22px;
  position: relative;
  right: 0px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type="number"] {
  -moz-appearance: textfield;
}

// Disable ripple effect
.MuiTouchRipple-root {
  display: none !important;
}

// Remove the space above iframe
.jss9 {
  display: none !important;
}
