@import "../../../styles/variable.scss";

.error-overlay {
  .content-panel {
    background-color: $backgroundOptionsBox;
    display: table;
    margin: 0 auto;
    width: 100%;
    text-align: center;
    z-index: 100;
    max-width: 590px;

    &-image {
      text-align: right;
    }

    .MuiTypography-h2 {
      color: $themeColor;
      font-family: $fontFamily;
      font-size: 44px;
      font-weight: 900;
      text-transform: uppercase;
      margin: 0 0 16px 0;
    }

    .MuiTypography-h5 {
      color: $textColor;
      font-family: $fontFamily;
      font-size: 30px;
      font-weight: normal;
      line-height: 36px;
      margin: 0;
    }

    .MuiTypography-body2 {
      color: $tableTdCellColor;
      font-family: $fontFamily;
      font-size: 18px;
      line-height: 22px;
      margin: 0 auto;
      width: 311px;
    }

    .expansion-panel {
      width: auto;
      margin: 0;
      padding: 0;

      .MuiExpansionPanelSummary-root {
        display: inline-flex;
        margin: 0 !important;

        .heading-text-size {
          color: #0b7eb5 !important;
          font-size: 18px !important;
          font-weight: normal;

          &::after {
            display: none;
          }
        }
      }

      .MuiAlert-root.MuiAlert-standardError {
        background-color: #ffebe5;
        margin: 16px auto 0 auto;
        max-width: 431px;
        padding: 16px 32px 16px 67px;

        .MuiAlert-icon,
        .MuiAlert-message {
          padding: 0;
        }

        .MuiAlert-message {
          color: #d8000c;
          font-family: $fontFamily;
          font-size: 12px;
        }
      }
    }
  }

  .image-panel {
    position: absolute;
    bottom: 0;
    width: 100%;

    .front {
      text-align: center;
      position: relative;
      margin-bottom: -22px;

      &-top {
        margin-bottom: 39px;
        margin-left: 79px;
      }
    }

    .back {
      .copyright-text {
        position: absolute;
        bottom: 26px;
        text-align: center;
        margin: 0 auto;
        width: 100%;
        display: block;
        color: $blackColor;
        font-family: $fontFamily;
        font-size: 12px;
        line-height: 15px;
      }
    }

    .back img {
      width: 100%;
    }
  }
}
