@import "../../styles/variable.scss";

.header-action {
  display: flex !important;
  margin-left: 76% !important;
}

.welcome-text {
  font-size: 24px !important;
}

.landing-toolbar {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.menu-button {
  margin-left: 8px !important;
  margin-right: 20px !important;
  padding: 6px !important;
}
.text-size {
  font-size: 16px !important;
  font-family: $fontFamily;
}

/*background for menu*/
/* TODO needs to change later*/
.menu-background-img {
  //background-image: url("../../assets/menu.png");
  background-repeat: no-repeat, repeat;
  padding-top: 76px !important;
}

.menu-background-white {
  // background-image: url("../../assets/menu.png");
  background-repeat: no-repeat, repeat;
  padding-top: 76px !important;
}

.user-profile {
  text-transform: none !important;
}

.user-profile span {
  padding-left: 12px;
}

/*Styles for pods*/
.pod-name {
  padding-top: 10px;
  text-align: left;
  padding-left: 10px;
}
.pod-name span:first-child {
  font-size: 14px;
  font-weight: 500;
}

.pod-name span:first-child + span {
  font-size: 14px;
  color: $grayColor;
}

.pod-count {
  font-size: 28px;
  text-align: right;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 28px;
  bottom: 19px;
}

.red {
  color: $redColor;
}

.light-red {
  color: $orangeColor;
}

.blue {
  color: $blueColor;
}

.green {
  color: $greenColor;
}

/*Style for action bar */
.action-bar {
  margin-top: 32.81px;
  display: flex;
  margin-bottom: 31.19px;
  float: right;
  width: 296px;
  .create-proposal-button {
    width: 152px;
    height: 39px;
  }
  svg {
    color: #29acdc;
  }
}

.action-bar-fix {
  margin-top: 32.81px;
  //display: flex;
  margin-bottom: 31.19px;
  //float: right;
  //width: 296px;

  #search-button {
    display: inline-block;
  }
  .create-proposal-button {
    width: 152px;
    height: 39px;
  }
  svg {
    color: #29acdc;
  }

  .text-right {
    text-align: right;
  }

  .vertical-center-heading {
    justify-content: center;
    flex-direction: column;
    color: #000000;
    font-family: $fontFamily;
    font-size: 18px;
    font-weight: bold;
  }

  .proposal-action-icon,
  .campaign-action-icon {
    width: 45px !important;
    height: 45px !important;
    margin-right: 30px !important;
    box-shadow: none !important;
    // border: 1px solid $lightGray;
    background-color: $whiteColor !important;
    button {
      padding: 0;
    }
  }
  .proposal-action-icon {
    border: 1px solid #f0f0f1;
  }
}

#search-button {
  display: flex;
}

.showSearchBar {
  margin-left: 61.18%;
}

.showSearchBar-fix {
  margin-left: 61.18%;
}

.viewActionButton {
  min-height: 38px;
}

.primary-button {
  background-color: $themeColor !important;
  color: $whiteColor !important;
  font-size: 14px !important;
  padding: 7px 19px !important;
  border-radius: 24.5px !important;
  border: 0px solid $themeColor !important;
  text-transform: none !important;
  font-family: $fontFamily;
}
.white-button {
  background-color: rgba(0, 0, 0, 0) !important;
  color: $themeColor !important;
  font-size: 14px !important;
  text-transform: none !important;
  font-family: $fontFamily;
  border-radius: 100px !important;
  margin-right: 15px !important;
  border: 1px solid $themeColor !important;
}
.heading-text-size {
  font-family: $fontFamily;
  font-size: 16px !important;
  font-weight: bold;
  color: $tableTdCellColorUnexpanded;
}

.proposalList {
  margin: 24px 34px auto 96px;
}
svg {
  font-size: 24px !important;
}
