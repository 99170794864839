@import "../../../styles/variable.scss";

.expansion-summary-heading {
  margin-bottom: $expansionSummaryMarginBottom !important;
}
.heading-text-size:after {
  position: absolute;
  content: "";
  height: 1px;
  background-color: #f0f0f1;
  width: 83%;
  margin-left: 15px;
  top: 50%;
}
.expansion-panel-error {
  color: $errorColor !important;
}

.selectField {
  svg {
    width: 24px !important;
  }
  .MuiSelect-select:focus {
    background-color: $defaultRowColor;
  }
}

.menu-parent {
  cursor: default !important;
  font-weight: 700 !important;
  pointer-events: none;
}

.menu-child {
  padding-left: 35px !important;
}
.MuiExpansionPanelSummary-expandIcon {
  transform: rotate(0deg) !important;
}
